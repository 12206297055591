import CustomInput from "../ui/customInput";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  checkCompanyStatus,
  resetAPIState,
} from "../../features/auth/authSlice";
import {
  fadeVariants,
  filterNumbers,
  filterNumbersWithMax,
} from "../../utils/helper";
import { motion } from "framer-motion";
import { MultiFileUpload } from "../ui/mutliFileUpload";
import { useEffect } from "react";
import { setError } from "../../features/ui/uiSlice";
import CustomButton from "../ui/customButton";
import validator from "validator";
import { useTranslation } from "react-i18next";

export default function CompanyRegistration({ handleNext }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let schema = yup.object().shape({
    name: yup.string().required(t("Company Name is Required")),
    address: yup.string().required(t("Address is Required")),
    phoneNumber: yup
      .string()
      .required(t("Phone Number is Required"))
      .test("is-valid-phone", t("Phone Number is not valid"), (value) =>
        validator.isMobilePhone(value || "")
      ),
    email: yup
      .string()
      .required(t("Email is Required"))
      .test("is-valid-email", t("Email is not valid"), (value) =>
        validator.isEmail(value || "")
      ),
    city: yup.string().required(t("City is Required")),
    registrationNo: yup
      .string()
      .min(10, t("Commercial Registration must be of 10 Digits"))
      .required(t("Commercial Registration No. is Required")),
    logo: yup.object().required(t("Logo is Required")),
  });

  const authState = useSelector((state) => state);

  const { company, companyStatus, isSuccess, isError, message, isLoading } =
    authState.auth;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      city: "",
      country: "",
      registrationNo: "",
      logo: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(checkCompanyStatus(`?registrationNo=${values.registrationNo}`));
    },
  });

  useEffect(() => {
    if (isError && message) {
      dispatch(setError(t("Registration No: ") + message || ""));
    }
  }, [isError, message]);

  useEffect(() => {
    if (isSuccess && companyStatus) {
      if (companyStatus?.message === "active") {
        dispatch(addCompany(formik.values));
        dispatch(resetAPIState());
        handleNext();
      }
    }
  }, [isSuccess, companyStatus]);

  useEffect(() => {
    if (company) {
      company?.name && formik.setFieldValue("name", company?.name);
      company?.email && formik.setFieldValue("email", company?.email);
      company?.phoneNumber &&
        formik.setFieldValue("phoneNumber", company?.phoneNumber);
      company?.address && formik.setFieldValue("address", company?.address);
      company?.city && formik.setFieldValue("city", company?.city);
      company?.registrationNo &&
        formik.setFieldValue("registrationNo", company?.registrationNo);
      company?.logo && formik.setFieldValue("logo", company?.logo);
    }
  }, [company]);

  return (
    <motion.div
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      transition="transition"
      exit="exit"
    >
      <form action="" onSubmit={formik.handleSubmit}>
        <CustomInput
          type="text"
          label={t("Commercial Registartion Number")}
          placeholder="1234567890"
          id="registrationNo"
          name="registrationNo"
          onChng={(e) =>
            formik.setFieldValue(
              "registrationNo",
              filterNumbersWithMax(e.target.value, 10)
            )
          }
          onBlr={formik.handleBlur("registrationNo")}
          val={formik.values.registrationNo}
          required={true}
        />
        <div className="error mt-2">
          {formik.touched.registrationNo && formik.errors.registrationNo}
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-2">
          <div className="w-full">
            <CustomInput
              type="text"
              label={t("Company Name (Alias)")}
              placeholder="Reli"
              id="name"
              name="name"
              onChng={formik.handleChange("name")}
              onBlr={formik.handleBlur("name")}
              val={formik.values.name}
              required={true}
            />
            <div className="error mt-2">
              {formik.touched.name && formik.errors.name}
            </div>
          </div>

          <div className="w-full">
            <CustomInput
              type="text"
              label={t("Company Email")}
              placeholder="info@reli.com"
              id="email"
              name="email"
              onChng={formik.handleChange("email")}
              onBlr={formik.handleBlur("email")}
              val={formik.values.email}
              required={true}
            />
            <div className="error mt-2">
              {formik.touched.email && formik.errors.email}
            </div>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-2">
          <div className="w-full">
            <CustomInput
              type="text"
              label={t("Company Phone Number")}
              placeholder="+96612345678"
              id="phoneNumber"
              name="phoneNumber"
              onChng={(e) => formik.setFieldValue("phoneNumber", e)}
              onBlr={formik.handleBlur("phoneNumber")}
              val={formik.values.phoneNumber}
              isPhoneNumber={true}
              required={true}
            />
            <div className="error mt-2">
              {formik.touched.phoneNumber && formik.errors.phoneNumber}
            </div>
          </div>

          <div className="w-full">
            <CustomInput
              type="text"
              label={t("Address")}
              placeholder="Street ABC Riyadh, Saudi Arabia"
              id="address"
              name="address"
              onChng={formik.handleChange("address")}
              onBlr={formik.handleBlur("address")}
              val={formik.values.address}
              required={true}
            />
            <div className="error mt-2">
              {formik.touched.address && formik.errors.address}
            </div>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-2">
          <div className="w-full">
            <CustomInput
              type="text"
              label={t("City")}
              placeholder="Riyadh"
              id="City"
              name="City"
              onChng={formik.handleChange("city")}
              onBlr={formik.handleBlur("city")}
              val={formik.values.city}
              required={true}
            />
            <div className="error mt-2">
              {formik.touched.city && formik.errors.city}
            </div>
          </div>

          <div className="w-full">
            <CustomInput
              type="text"
              label={t("Country")}
              placeholder="Saudi Arabia"
              id="country"
              name="country"
              onChng={formik.handleChange("country")}
              onBlr={formik.handleBlur("country")}
              val={"Saudi Arabia"}
              required={true}
              disabled={true}
            />
            <div className="error mt-2">
              {formik.touched.country && formik.errors.country}
            </div>
          </div>
        </div>

        <div className="w-full">
          <MultiFileUpload
            listType="picture-card"
            label="Upload Logo (60 x 60px)"
            uploadFiles={company && [formik.values.logo]}
            setUploadFiles={(file) => formik.setFieldValue("logo", file[0])}
            accept={"image/jpeg, image/jpg, image/png, image/svg"}
            required={true}
            max={1}
          />
          <div className="error mt-2">
            {formik.touched.logo && formik.errors.logo}
          </div>
        </div>

        <CustomButton isLoading={isLoading}>{t("Next")}</CustomButton>
      </form>
    </motion.div>
  );
}

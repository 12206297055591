import React, { useEffect, useState } from "react";
import CustomInput from "../ui/customInput";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, resetState } from "../../features/auth/authSlice";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { fadeVariants, filterNumbers, handleLogout } from "../../utils/helper";
import CustomButton from "../ui/customButton";
import { setError } from "../../features/ui/uiSlice";
import validator from "validator";
import { Modal } from "antd";
import { MdVerified } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function UserRegistration({ handleNext, isCustomer = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let schema = yup.object().shape({
    fullName: yup.string().required(t("Fullname is Required")),
    phoneNumber: yup
      .string()
      .required(t("Phone Number is Required"))
      .test("is-valid-phone", t("Phone Number is not valid"), (value) =>
        validator.isMobilePhone(value || "")
      ),
    email: yup
      .string()
      .required(t("Email is Required"))
      .test("is-valid-email", t("Email is not valid"), (value) =>
        validator.isEmail(value || "")
      ),
    role: yup.string().required(t("Role is Required")),
    password: yup
      .string()
      .required(t("Password is Required"))
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*^\S+[^A-Za-z0-9])(?!.* )(?=.{8,35}$)/,
        t(
          "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character"
        )
      ),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "+966",
      role: isCustomer ? 2 : 1,
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });

  const authState = useSelector((state) => state);

  const { register_user, isError, isSuccess, isLoading, message } =
    authState.auth;

  useEffect(() => {
    if (isSuccess && register_user) {
      if (isCustomer) {
        setSuccessModal(true);
      } else {
        handleNext();
      }
    }
  }, [registerUser, isError, isSuccess]);

  useEffect(() => {
    if (isError && message) {
      dispatch(setError(message || ""));
      dispatch(resetState());
    }
  }, [isError, message]);

  /* Modal */
  const [successModal, setSuccessModal] = useState(false);

  return (
    <motion.div
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      transition="transition"
      exit="exit"
    >
      <Modal
        open={successModal}
        keyboard={false}
        maskClosable={false}
        footer=""
        centered
      >
        <div className="p-6">
          <div className="flex flex-column gap-2 items-center justify-center">
            <MdVerified size={82} color="#09cfab" />
            <h3 className="text-center title">
              {t("Your Account Has Been Created")}
            </h3>
            <p className="text-center text-xl">
              {t(
                "A verification email has been sent to your inbox. Please verify your email address before logging in."
              )}
            </p>
            <CustomButton onClick={handleLogout}>
              {t("I’ve Verified My Email")}
            </CustomButton>
          </div>
        </div>
      </Modal>
      <form action="" onSubmit={formik.handleSubmit}>
        <CustomInput
          type="text"
          label={t("Full Name")}
          placeholder="John Doe"
          id="fullName"
          name="fullName"
          onChng={formik.handleChange("fullName")}
          onBlr={formik.handleBlur("fullName")}
          val={formik.values.fullName}
          required={true}
        />
        <div className="error mt-2">
          {formik.touched.fullName && formik.errors.fullName}
        </div>

        <CustomInput
          type="text"
          label={t("Phone Number")}
          placeholder="+966"
          id="phoneNumber"
          name="phoneNumber"
          onChng={(e) => formik.setFieldValue("phoneNumber", e)}
          onBlr={formik.handleBlur("phoneNumber")}
          val={formik.values.phoneNumber}
          isPhoneNumber={true}
          required={true}
        />
        <div className="error mt-2">
          {formik.touched.phoneNumber && formik.errors.phoneNumber}
        </div>

        <CustomInput
          type="text"
          label={t("Email Address")}
          placeholder="johndoe@gmail.com"
          id="email"
          name="email"
          onChng={formik.handleChange("email")}
          onBlr={formik.handleBlur("email")}
          val={formik.values.email}
          required={true}
        />
        <div className="error mt-2">
          {formik.touched.email && formik.errors.email}
        </div>

        <CustomInput
          type="password"
          label={t("Password")}
          placeholder="..."
          id="pass"
          name="password"
          onChng={formik.handleChange("password")}
          onBlr={formik.handleBlur("password")}
          val={formik.values.password}
          isPassword={true}
          required={true}
        />
        <div className="error mt-2">
          {formik.touched.password && formik.errors.password}
        </div>

        <div className="mt-4 mb-2">
          {t("Already have an account?")}
          <Link
            to="/auth/login"
            className="border-0 text-textLink underline px-2"
          >
            {t("Login")}
          </Link>
        </div>
        {/* {!isCustomer ? (
          <div>
            Register as
            <Link
              to="/auth/register/customer"
              className="border-0 text-textLink underline mx-2"
            >
              Customer
            </Link>
          </div>
        ) : (
          <div>
            Register as
            <Link
              to="/auth/register/merchant"
              className="border-0 text-textLink underline mx-2"
            >
              Merchant
            </Link>
          </div>
        )} */}

        <CustomButton isLoading={isLoading}>
          {!isCustomer ? t("Next") : t("Register")}
        </CustomButton>
      </form>
    </motion.div>
  );
}

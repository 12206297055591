import { base_url, uploads_uri } from "./baseUrl";
import user_avatar from "../assets/image/avatar_image.webp";
import useQuery from "../hooks/useQuery";
import { toast } from "react-toastify";
import authService from "../features/auth/authServices";
import { UserRoles } from "./constant";
import useAuthUser from "../hooks/useAuthUser";
import { setError } from "../features/ui/uiSlice";
import { useDispatch } from "react-redux";

export const handleCheckFileSize = (file) => {
  const limit = {
    video: 100, // MB
    image: 5, // MB
    file: 15, // MB
  };

  let maxSize;
  if (file.type.startsWith("video/") || file.type.startsWith("audio/")) {
    maxSize = limit.video * 1024;
  } else if (file.type.startsWith("image/")) {
    maxSize = limit.image * 1024;
  } else {
    maxSize = limit.file * 1024;
  }

  if (file.size / 1024 > maxSize) {
    return false;
  }
  return true;
};

export const getActiveLink = () => {
  return window.location.pathname.split("/").pop() || "";
};

export const handleLogout = async (withApi = false) => {
  try {
    if (withApi) {
      await authService.logout();
    }
    localStorage.clear();
    window.location.href = "/auth/login";
  } catch (error) {
    console.log(error);
    localStorage.clear();
    window.location.href = "/auth/login";
  }
};

export const extractError = (res) => {
  return res.error[0].responseMessage;
};

export const getFileFromServer = (file) => `${uploads_uri}${file}`;

export const userAvatar = user_avatar;

export const filterNumbers = (str) => {
  return str.replace(/[a-zA-Z]/g, "").trim();
};

export const filterNumbersWithMax = (str, max) => {
  let numericString = str.replace(/[^\d]/g, "").trim();

  if (numericString.length > max) {
    numericString = numericString.slice(0, max);
  }
  return Number(numericString);
};

export const filterString = (str) => {
  return str.replace(/\d/g, "");
};

export const fadeVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 1.8,
  },
};

export const popupVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.9,
  },
  transition: {
    ease: "easeInOut",
  },
};

export const slideVariants = {
  initial: {
    opacity: 0,
    x: 200,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 200,
  },
  transition: {
    type: "inertia",
    velocity: 50,
    duration: 1.4,
  },
};

export const scrollToTop = () => {
  typeof window !== undefined && window.scrollTo(0, 0);
};

export const scrollToBottom = (ref) => {
  ref.current.scrollTop = ref.current.scrollHeight;
  ref.current.style.scrollBehavior = "smooth";
};

export const QRRedirection = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  let code = query.get("code");

  const { authUser } = useAuthUser();

  if (!authUser?.isLogin) {
    handleLogout(false);
    dispatch(setError("Please Login First"));
    return;
  }

  if (!authUser?.isProfileComplete) {
    window.location.href = "/auth/register/merchant";
    return;
  }

  if (parseInt(authUser?.role) <= 2) {
    window.location.href = `/user/verify-qr?code=${code}`;
    return;
  } else {
    window.location.href = `/admin/verify-qr?code=${code}`;
    return;
  }
};

const colors = [
  "rgba(75, 192, 192, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(255, 205, 86, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(201, 203, 207, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(0, 123, 255, 0.2)",
  "rgba(40, 167, 69, 0.2)",
  "rgba(255, 193, 7, 0.2)",
  "rgba(23, 162, 184, 0.2)",
  "rgba(108, 117, 125, 0.2)",
];

const borderColors = [
  "rgb(75, 192, 192)",
  "rgb(54, 162, 235)",
  "rgb(255, 99, 132)",
  "rgb(255, 205, 86)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
  "rgb(255, 159, 64)",
  "rgb(0, 123, 255)",
  "rgb(40, 167, 69)",
  "rgb(255, 193, 7)",
  "rgb(23, 162, 184)",
  "rgb(108, 117, 125)",
];

export const generateDatasetColors = (index) => ({
  backgroundColor: colors[index % colors.length],
  borderColor: borderColors[index % borderColors.length],
});

export const generateColors = (index) => colors[index % colors.length];

export const generateChartData = (data) => {
  const colors = [
    "rgba(75, 192, 192, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 99, 132, 0.2)",
    "rgba(255, 205, 86, 0.2)",
    "rgba(153, 102, 255, 0.2)",
  ];

  const borderColors = [
    "rgb(75, 192, 192)",
    "rgb(54, 162, 235)",
    "rgb(255, 99, 132)",
    "rgb(255, 205, 86)",
    "rgb(153, 102, 255)",
  ];

  const generateDatasetColors = (index) => ({
    backgroundColor: colors[index % colors.length],
    borderColor: borderColors[index % borderColors.length],
  });

  return {
    mostScannedProductsChart: {
      labels: data.mostScannedProducts.map(
        (item) => `${item._id.month}/${item._id.year}`
      ),
      datasets: data.mostScannedProducts.map((item, index) => ({
        label: `Product ${item._id.productId}`,
        data: item.totalScans,
        backgroundColor: generateDatasetColors(index).backgroundColor,
        borderColor: generateDatasetColors(index).borderColor,
        borderWidth: 1,
      })),
    },
    orderTrendsChart: {
      labels: data.orderTrends.map(
        (item) => `${item._id.month}/${item._id.year}`
      ),
      datasets: [
        {
          label: "Orders",
          data: data.orderTrends.map((item) => item.totalOrders),
          backgroundColor: colors[0],
          borderColor: borderColors[0],
          borderWidth: 1,
        },
      ],
    },
    productTrendsChart: {
      labels: data.productTrends.map(
        (item) => `${item._id.month}/${item._id.year}`
      ),
      datasets: [
        {
          label: "Products",
          data: data.productTrends.map((item) => item.totalProducts),
          backgroundColor: colors[1],
          borderColor: borderColors[1],
          borderWidth: 1,
        },
      ],
    },
  };
};

export const getUserRole = (role) => {
  return UserRoles.find((item) => item.id === parseInt(role));
};

export const downloadFile = (fileUrl, name = "") => {
  return fetch(getFileFromServer(fileUrl))
    .then((response) => response.blob())
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);

      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = name ? name : fileUrl;
      alink.click();

      return true;
    })
    .catch((error) => {
      console.error("Download failed:", error);
      return false;
    });
};

export const mapAttachments = (attachments, t) => {
  let arr = [];
  if (attachments?.length > 0) {
    if (attachments[0]?.commercialRegistration) {
      arr.push({
        title: t("Commercial Registration"),
        file: attachments[0]?.commercialRegistration?.file,
        fileName: attachments[0]?.commercialRegistration?.name,
      });
    }
    if (attachments[0]?.vatCertificate) {
      arr.push({
        title: t("VAT Certificate"),
        file: attachments[0]?.vatCertificate?.file,
        fileName: attachments[0]?.vatCertificate?.name,
      });
    }
    if (attachments[0]?.tradeLicense) {
      arr.push({
        title: t("Trade License"),
        file: attachments[0]?.tradeLicense?.file,
        name: attachments[0]?.tradeLicense?.name,
      });
    }
  }

  return arr;
};

export const getProductTemplate = () => {
  return base_url + "product/download/template";
};

import React, { useEffect, useState } from "react";
import CustomInput from "../components/ui/customInput";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  addUser,
  login,
  loginOTP,
  resetState,
} from "../features/auth/authSlice";
import CustomButton from "../components/ui/customButton";
import {
  filterNumbers,
  filterNumbersWithMax,
  getFileFromServer,
} from "../utils/helper";
import useAuthUser from "../hooks/useAuthUser";

import pattern_navy from "../assets/image/Pattern_Small_Navy.svg";
import Full_Logo from "../assets/image/full_logo.svg";
import { setError } from "../features/ui/uiSlice";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import { FullScreenLoader } from "../components/loaders/loading";

export default function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Email should be valid"))
      .required(t("Email is Required")),
    password: yup.string().required(t("Password is Required")),
  });

  const otpSchema = yup.object().shape({
    otp: yup
      .string()
      .min(6, t("otp must be at least 6 digits"))
      .required(t("OTP is Required")),
  });

  const { authUser } = useAuthUser();

  const [isOTP, setIsOTP] = useState(false);
  const [isScanOTP, setIsScanOTP] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  const formikOTP = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpSchema,
    onSubmit: (values) => {
      const query = `?otp=${values.otp}`;
      dispatch(loginOTP(query));
    },
  });

  const authState = useSelector((state) => state);
  const { loginUser, isError, isSuccess, isLoading, message } = authState.auth;

  useEffect(() => {
    if (authUser?.isLogin) {
      window.location.href = parseInt(authUser?.role) <= 2 ? "/user" : "/admin";
    }
  }, [authUser]);

  useEffect(() => {
    if (isSuccess && loginUser) {
      if (loginUser?.user) {
        dispatch(addUser(loginUser?.user));
        if (loginUser?.company) {
          dispatch(addCompany(loginUser?.company));
        }
        window.location.href =
          parseInt(authUser?.role) <= 2 ? "/user" : "/admin";
      } else if (loginUser?.image && loginUser?.jwToken) {
        setIsScanOTP(true);
        setIsOTP(false);
      } else if (loginUser?.jwToken) {
        setIsOTP(true);
        setIsScanOTP(false);
      }
    }
  }, [loginUser, isError, isSuccess]);

  useEffect(() => {
    if (isError && message) {
      dispatch(setError(message || ""));
      dispatch(resetState());
    }
  }, [isError, message]);

  /* Langauge */
  const { language } = useLanguage();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const changeLanguage = () => {
    let newLang = language;

    switch (language) {
      case "en":
        i18n.changeLanguage("ar");
        newLang = "ar";
        break;
      case "ar":
        i18n.changeLanguage("en");
        newLang = "en";
        break;
      default:
        break;
    }

    localStorage.setItem("lang", newLang);
    setLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <div className="grid grid-cols-12 !bg-secondary min-h-screen">
      <FullScreenLoader loading={loading} />
      <div
        className="py-5 max-lg:hidden lg:col-span-6 2xl:col-span-7 flex items-center justify-center min-h-screen relative"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${pattern_navy}') no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <img src={Full_Logo} className="h-[220px] w-full z-10" alt="logo" />
      </div>
      <div className="col-span-12 lg:col-span-6 2xl:col-span-5 flex items-center justify-center w-full min-h-screen overflow-y-auto !bg-primary">
        <div className="gap-2 p-4 w-full md:mx-20">
          <div
            className="w-fit text-textLink underline font-semibold text-xxl cursor-pointer uppercase"
            onClick={changeLanguage}
          >
            {language == "ar" ? "EN" : "AR"}
          </div>
          <img
            src={Full_Logo}
            className="h-[100px] w-full lg:hidden"
            alt="logo"
          />
          {isScanOTP ? (
            <>
              <h3 className="text-center title">{t("Scan OTP")}</h3>
              <p className="text-center">
                {t("Scan the QR code with")}{" "}
                <a
                  href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-textLink"
                >
                  Google
                </a>{" "}
                {t("or")}{" "}
                <a
                  href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-textLink"
                >
                  Microsoft
                </a>{" "}
                Authenticator app
              </p>
              <form onSubmit={formikOTP.handleSubmit}>
                <div className="flex flex-col items-center justify-center gap-2 p-2 py-4 rounded">
                  <img
                    src={getFileFromServer(loginUser.image)}
                    style={{
                      height: `200px`,
                      width: `200px`,
                    }}
                    alt="QR Code"
                  />
                </div>
                <CustomButton
                  onClick={() => {
                    setIsOTP(true);
                    setIsScanOTP(false);
                  }}
                >
                  {t("Next")}
                </CustomButton>
              </form>
            </>
          ) : isOTP ? (
            <>
              <h3 className="text-center title">{t("OTP")}</h3>
              <p className="text-center">{t("Verify OTP to continue")}</p>
              <form onSubmit={formikOTP.handleSubmit}>
                <CustomInput
                  type="text"
                  label={t("OTP")}
                  placeholder="..."
                  id="otp"
                  name="otp"
                  // onChng={(e) =>
                  //   formikOTP.setFieldValue(
                  //     "otp",
                  //     filterNumbersWithMax(e.target.value, 6)
                  //   )
                  // }
                  onChng={(e) => formikOTP.setFieldValue("otp", e)}
                  onBlr={formikOTP.handleBlur}
                  val={formikOTP.values.otp}
                  isOTP={true}
                  isAutoComplete={false}
                />
                <div className="error mt-2">
                  {formikOTP.touched.otp && formikOTP.errors.otp}
                </div>

                <CustomButton isLoading={isLoading} type="submit">
                  {t("Verify")}
                </CustomButton>
              </form>
            </>
          ) : (
            <>
              <h3 className="text-center title">{t("Login")}</h3>
              <p className="text-center">
                {t("Login to your account to continue")}
              </p>
              <form onSubmit={formik.handleSubmit}>
                <CustomInput
                  type="text"
                  label={t("Email Address")}
                  placeholder="jhondoe@gmail.com"
                  id="email"
                  name="email"
                  onChng={formik.handleChange}
                  onBlr={formik.handleBlur}
                  val={formik.values.email}
                  isAutoComplete
                  required={true}
                />
                <div className="error mt-2">
                  {formik.touched.email && formik.errors.email}
                </div>
                <CustomInput
                  type="password"
                  label={t("Password")}
                  placeholder="..."
                  id="pass"
                  name="password"
                  onChng={formik.handleChange}
                  onBlr={formik.handleBlur}
                  val={formik.values.password}
                  isAutoComplete
                  isPassword={true}
                  required={true}
                />
                <div className="error mt-2">
                  {formik.touched.password && formik.errors.password}
                </div>
                <div className="mt-4 mb-2">{t("Don't have an account?")}</div>
                <div className="flex flex-column lg:!flex-row gap-2">
                  <Link
                    to="/auth/register/merchant"
                    className="border-0 text-textLink underline"
                  >
                    {t("Register")}
                    {/* Register as Merchant */}
                  </Link>
                  {/* <div>{"OR "}</div>
                  <Link
                    to="/auth/register/customer"
                    className="border-0 text-textLink underline"
                  >
                    Register as Customer
                  </Link> */}
                </div>
                <CustomButton isLoading={isLoading} type="submit">
                  {t("Login")}
                </CustomButton>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
